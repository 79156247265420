import React from "react";
import PropTypes from "prop-types";

const Label = ({id, text, required, wordCount}) => {
    return (
        <label htmlFor={id}
               className="mb-1 text-[13px] leading-[18px] font-medium md:mb-1.5 md:text-[14px] md:leading-[21px]">{text}{required && '*'}{wordCount &&
            <span className='text-primaryColor1 ml-2 text-[90%]'><span>{wordCount.min}</span>{wordCount.max && `/${wordCount.max}`}</span>}</label>
    );
};

Label.defaultValues = {
    required: false
}

Label.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string.isRequired,
    required: PropTypes.bool,
    wordCount: PropTypes.object
}

export default Label;
