export default function IconAlert(){
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_308_2017)">
                <path d="M5.24004 1.5127H10.76L14.6667 5.41936V10.9394L10.76 14.846H5.24004L1.33337 10.9394V5.41936L5.24004 1.5127Z" stroke="#DC4049" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 5.5127V8.17936" stroke="#DC4049" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 10.8467H8.00625" stroke="#DC4049" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_308_2017">
                <rect width="16" height="16" fill="white" transform="translate(0 0.179688)"/>
            </clipPath>
            </defs>
        </svg>
    )
}