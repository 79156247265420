const WhatIsToneOfTheMessage = {
    defaultValue: 'Formal',
    data: [
        {
            title: 'Formal',
            value: 'formal',
        },
        {
            title: 'Informal',
            value: 'informal'
        },
        {
            title: 'Funny',
            value: 'funny'
        }
    ]
}

export default WhatIsToneOfTheMessage;