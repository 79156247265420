import React from "react";
import {FaRegBell, FaRegUser} from 'react-icons/fa'
import {BiX} from 'react-icons/bi'
import Logo from "../Logo";
import UserBlock from "../User/UserBlock";
import Navbar from "../Navbar";
import IconButton from "../Button/IconButton";
import {useAuthProviderContext} from "../../Providers/AuthProvider";
import {useSidebarProviderContext} from "../../Providers/SidebarProvider";

const Sidebar = () => {

    const {sidebar, sidebarHandle} = useSidebarProviderContext();
    const {logout} = useAuthProviderContext();

    return (
        <div
            className={`${sidebar ? 'ml-0' : 'ml-[-280px]'} fixed z-20 flex ease-in-out duration-500 flex-col bg-primaryColor3 py-4 px-6 overflow-y-visible w-[280px] min-h-screen md:relative`}>
            <div className='flex justify-between items-center max-w-full mb-4'>
                <div className='flex max-w-[140px]'>
                    <Logo logo='logo-light.svg'/>
                </div>
                <div className='relative flex flex-wrap gap-2'>
                    <IconButton icon={<FaRegBell/>} notification=""/>
                    <IconButton icon={<FaRegUser/>} dropDownOptions={[
                        {text: 'Logout', handleClick: logout}
                        // {link: '/logout', text: 'Logout'}
                    ]}/>
                </div>
            </div>
            <UserBlock/>
            <div id="navbar" className='flex flex-col'>
                <Navbar/>
            </div>
            <BiX className='absolute top-0 text-3xl right-0 text-white cursor-pointer md:hidden'
                 onClick={() => sidebarHandle(!sidebar)}/>
        </div>
    );
};

export default Sidebar;