import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { boolean, object, string } from "yup";
import { useAuthProviderContext } from "../../Providers/AuthProvider";
import Button from "../../Components/Button";
import FormField from "../../Components/Forms/FormField";
import { handleLogin, handle2faCheck, handleLinkedinLogin } from "../../services/auth";
import IconGoogle from "../../Components/Icons/IconGoogle";
import axios from "axios";
import { getCookie } from "../../Providers/AuthProvider";
import { axiosInterceptor } from "../../AxiosInstance";
import loader from "../../assets/img/loader.gif";
import { SERVER_BASE_URL } from "../../utils/constants";

const schema = object({
  email: string().email().required("The field is required"),
  password: string().required("The field is required"),
  agrement: boolean().oneOf([true], "The field is required").required("The field is required"),
}).required();

const schemaOTP = object({
  code: string().required("The field is required"),
}).required();

const Login = () => {
  const [submitBtnloading, setSubmitBtnloading] = useState(false);
  const [is2FA, setIs2FA] = useState(false);
  const [body, setBody] = useState({});
  const [apiResponse, setApiResponse] = useState({
    text: "",
    cls: "text-primaryColor4",
  });

  const { auth, setAuth } = useAuthProviderContext();
  const navigate = useNavigate();

  console.log("login page");

  // useEffect(() => {
  //   const authStorageToken = localStorage.getItem("authStorageToken");
  //   if (authStorageToken) {
  //     localStorage.removeItem("authStorageToken");
  //     // document.cookie =
  //     //   "connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     //window.location.reload(true);
  //   }
  // }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register: registerOtp,
    handleSubmit: handleSubmitOtp,
    formState: { errors: errorsOtp },
  } = useForm({
    resolver: yupResolver(schemaOTP),
  });

  const login = async (data) => {
    console.log(data);
    setBody(data);
    setSubmitBtnloading(true);
    console.log(auth);

    try {
      const response = await handleLinkedinLogin({
        userEmail: auth.user.email,
        firstName: auth.user.first_name,
        lastName: auth.user.last_name,
        linkedInLogin: data.email,
        linkedInPass: data.password,
        userAgent: navigator.userAgent,
        token: auth?.token || "",
      });

      const apiResponse = response.data;

      if (apiResponse.auth_2fa) {
        // If 2FA is required, update state and show 2FA input
        setSubmitBtnloading(false);
        console.log("require 2fa");
        setIs2FA(true);
        setBody((prev) => ({ ...prev, ...apiResponse }));
        setApiResponse({
          text: "2FA required. Please enter the code sent to your device.",
          cls: "text-primaryColor4",
        });
      } else {
        // Handle the final login step
        handleAuthFinish(apiResponse);
      }
    } catch (err) {
      const msg = err.response.data.message;
      setSubmitBtnloading(false);
      setApiResponse({
        text: msg.includes("Invalid credentials") ? msg : "An error occurred during login",
        cls: "text-primaryColor4",
      });
      console.error(err);
    }
  };

  const handle2FA = async (data) => {
    console.log(body);
    console.log(data);
    setSubmitBtnloading(true);

    try {
      const response = await handle2faCheck({
        code: data.code,
        url: body.url,
        email: body.email,
        password: body.password,
      });

      const apiResponse = response.data;
      if (!apiResponse.success) {
        throw new Error();
      }
      // Handle the final login step based on 2FA result
      handleAuthFinish(apiResponse);
    } catch (err) {
      setSubmitBtnloading(false);
      setApiResponse({
        text: "2FA verification failed. Please try again.",
        cls: "text-primaryColor4",
      });
      console.error(err);
    }
  };

  // Helper function to handle the auth_finish logic
  const handleAuthFinish = (data) => {
    setSubmitBtnloading(false);
    console.log(data.message);

    if (!data.success) {
      setIs2FA(false);
      setApiResponse({
        text: data.message,
        cls: "text-primaryColor4",
      });
    } else {
      // Calculate max-age for half a year
      const halfYearInSeconds = Math.round(35 * 24 * 60 * 60); // days * hours/day * minutes/hour * seconds/minute

      // Set the linkedinAuthorized cookie with calculated max-age
      document.cookie = `linkedinAuthorized=true; path=/; max-age=${halfYearInSeconds}`;
      window.location.href = "https://www.linkedin.com/";
    }
  };

  if (is2FA) {
    return (
      <React.Fragment>
        <div className="flex flex-row py-8 justify-between">
          <img src={`/img/logo-dark.svg`} class="w-[139px] h-[42px] m-auto" alt="Outreach AI logo" />
          <img src={`/img/sync.png`} class="w-[25px] h-[25px] m-auto" alt="Likedin logo" />
          <img src={`/img/linkedin_logo.png`} class="w-[35px] h-[35px] m-auto" alt="Likedin logo" />
        </div>
        <p className="text-[14px] mb-[16px] text-center">Please enter your one time verification code</p>
        <p className="text-[12px] mb-[28px] text-center text-textGrey">This has been sent to your email or phone</p>

        {submitBtnloading ? (
          <>
            <img src={loader} className="w-[104px] h-[104px] mt-[51px] mb-[20px] mx-auto" />
            <p className="text-[16px] mb-[81px] text-center font-bold">It might take a couple of minutes, please, wait. Thank you!</p>
          </>
        ) : (
          <form
            className="flex flex-col"
            onSubmit={handleSubmitOtp((data) => {
              handle2FA(data);
            })}
          >
            <FormField customClass="mb-[28px]" register={registerOtp} id="code" placeholder="Code" />
            <Button
              disabled={isValid}
              text="Validate"
              classes="login-btn mt-[28px] width-[100%]"
              submitBtnloading={{
                loading: submitBtnloading,
                text: "Signing In",
              }}
            />

            <div className="flex justify-center flex-col items-center">
              <p className={`font-bold ${apiResponse.cls}`}>{apiResponse.text}</p>
            </div>
          </form>
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {/* <Logo /> */}

      <div className="flex flex-row py-8 justify-between">
        <img src={`/img/logo-dark.svg`} class="w-[139px] h-[42px] m-auto" alt="Outreach AI logo" />
        <img src={`/img/sync.png`} class="w-[25px] h-[25px] m-auto" alt="Likedin logo" />
        <img src={`/img/linkedin_logo.png`} class="w-[35px] h-[35px] m-auto" alt="Likedin logo" />
      </div>
      {submitBtnloading ? (
        <>
          <img src={loader} className="w-[104px] h-[104px] mt-[51px] mb-[20px] mx-auto" />
          <p className="text-[16px] mb-[81px] text-center font-bold">It might take a couple of minutes, please, wait. Thank you!</p>
        </>
      ) : (
        <>
          <p className="text-[14px]">
            Please Enter your <b>LinkedIn Email</b> and <b>LinkedIn Password</b>
          </p>
          <form
            className="flex flex-col"
            onSubmit={handleSubmit((data) => {
              login(data);
            })}
          >
            <FormField
              errors={errors}
              register={register}
              id="email"
              placeholder="LinkedIn Email"
              ariaDescribedby={{
                id: "emailHelp",
              }}
            />
            <FormField errors={errors} register={register} id="password" placeholder="LinkedIn Password" type="password" />
            <FormField
              errors={errors}
              register={register}
              customClass="agrement-checkbox"
              id="agrement"
              label={true}
              text={
                <p>
                  I accept the <a href="#">Terms of use</a> and <a href="#">Privacy Policy</a>
                </p>
              }
              type="checkbox"
            />
            <Button
              disabled={isValid}
              text="Continue"
              classes="login-btn"
              submitBtnloading={{
                loading: submitBtnloading,
                text: "Signing In",
              }}
            />
            <div className="flex justify-center flex-col items-center">
              <p className={`font-bold ${apiResponse.cls}`}>{apiResponse.text}</p>
            </div>
          </form>
        </>
      )}
    </React.Fragment>
  );
};

export default Login;
