import React from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {useAuthProviderContext} from "../../../Providers/AuthProvider";
import Sidebar from "../../Sidebar";
import TopNav from "../../Header/TopNav";
import Loader from "../../Loader/Loader"

const DashboardLayout = () => {

  const navigate = useNavigate();
    const {auth} = useAuthProviderContext();
    if (auth.isLoading) return <Loader />
    if(!auth.user) return navigate('/login');
    return (
        <div className='wrapper flex'>
            <Sidebar/>
            <div className='bg-greyColor3 flex-1 flex flex-col min-h-screen'>
                <TopNav/>
                <section>
                    <Outlet/>
                </section>
            </div>
        </div>
    );
};

export default DashboardLayout;
