const FaqsData = [
    {
        "head": "Can OutreachAI help me with my connections on LinkedIn?",
        "text": "Absolutely! It will increase your connection rate and the speed in generating a personalized message that resonates.",
        "expanded": true
    },
    {
        "head": "What do I need to setup OutreachAI?",
        "text": "Just need Google Chrome and then sign up!"
    },
    {
        "head": "How many messages can I generate?",
        "text": "It’s based on words generated and there’s a cap per each membership."
    },
    {
        "head": "Why use OutreachAI?",
        "text": "To help create personalize messages quicker for each prospect on your prospect list."
    },
    {
        "head": "Are the messages being generated accurate?",
        "text": "Yes! They’re very accurate based on how you input the necessary fields. We are going to continue enhancing with a major focus on accuracy as well. More features to come!"
    },
    {
        "head": "Is it worth my time to sign up?",
        "text": "Yes!! It’s free and you can sign up and download the extension in under a minute!"
    },
    {
        "head": "If I have questions, who can I contact?",
        "text": "You can contact michael@outreachAI.co for any questions."
    }
]

export default FaqsData;