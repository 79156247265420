import axiosInstance from "../AxiosInstance";

export const handleLogin = async (data) => await axiosInstance.post('/auth/login', data);
export const handleLinkedinLogin = async (data) => await axiosInstance.post('/user/linkedin-login', data);
export const handle2faCheck = async (data) => await axiosInstance.post('/user/2fa', data);

export const handleGoogleLogin = async (data) => await axiosInstance.post('/auth/google', data);

export const handleGoogleRedirect = async (data) => await axiosInstance.get('/auth/google/callback', data);

export const getProfile = async () => await axiosInstance.get('/user/get-profile');

export const getLinkedInAuth = async () => await axiosInstance.get('/user/check');

export const forgotPassword = async (data) => await axiosInstance.post('/auth/forgotpwd', data);
export const resetPasswd = async (data) => await axiosInstance.post('/auth/resetpwd', data);

export const handleRegister = async (data) => await axiosInstance.post('/auth/register', data);

// import axiosInstance from "../AxiosInstance";
//
// export const handleLogin = async (data) => await axiosInstance.post('/auth/login', {
//         email: data.email,
//         password: data.password
//         // email: 'test12@gmail.com',
//         // password: '123456'
//     }
// );
//
// export const getProfile = async token => await axiosInstance.get('/user/getProfile',
//     {
//         headers: {
//             'Authorization': 'Bearer ' + token
//         }
//     }
// );
