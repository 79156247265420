import React, { useRef, useState } from "react";
import { format } from "date-fns";
import { FaStar, FaRegCopy, FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContentProviderContext } from "../../Providers/ContentProvider";
import { handleUpdateContent } from "../../services/content";

const CardItem = ({ message, page }) => {
  const { favorites, history, setFavorites, setHistory } =
    useContentProviderContext();

  var date = new Date(message.created_at);
  var formattedDate = format(date, "H:mma, MMMM do, yyyy");
  const textAreaRef = useRef(null);

  function copyToClipboard() {
    navigator.clipboard.writeText(message.response);
    toast("Copied!");
  }

  const favoritesMethod = page === "history" ? setHistory : setFavorites;

  const handleFav = async (id, isFav) => {
    const data = page === "history" ? history : favorites;
    favoritesMethod({ data: data.data, isLoading: true });
    console.log(id)
    const res = await handleUpdateContent({
      content_id: id,
      type: "fav",
      is_fav: !isFav,
    });
    favoritesMethod({
      isLoading: false,
      data:
        page === "favorites"
          ? data.data.filter((item) => item._id !== id)
          : data.data.map((item) => {
              if (item._id === id) return res.data.data;
              return item;
            }),
    });
  };

  return (
    <div className="card flex flex-col rounded-lg bg-white p-6 border-b-[0.8px] md:p-10">
      <p
        className="text-[14px] leading-[22px] mb-4 md:text-[16px] leading-[24px]"
        ref={textAreaRef}
      >
        {message.response}
      </p>
      <div className="flex justify-between items-center">
        <div className="flex flex-wrap gap-3 items-center">
          <div className="text-[13px] leading-[20px] text-primaryColor3 text-opacity-70 font-bold">
            Generated - {formattedDate}
          </div>
          <ul className="flex flex-wrap gap-2 items-center">
            <li
              className={`cursor-pointer flex justify-center items-center w-8 h-8 bg-greyColor2 rounded text-white hover:text-primaryColor1 ${
                message.is_fav && `active`
              }`}
              onClick={() => handleFav(message.id, message.is_fav)}
            >
              <FaStar />
            </li>
            <li
              className="cursor-pointer flex justify-center items-center w-8 h-8 bg-greyColor2 rounded text-white hover:text-primaryColor1"
              onClick={copyToClipboard}
            >
              <FaRegCopy />
              <ToastContainer autoClose={1000} />
            </li>
          </ul>
        </div>
        {/*<div className='flex items-center'>*/}
        {/*    <ul className='flex flex-wrap gap-2 items-center'>*/}
        {/*        <li className={`cursor-pointer flex justify-center items-center w-8 h-8 bg-greyColor2 rounded text-white hover:text-primaryColor1 ${message.like && `active`}`}>*/}
        {/*            <FaThumbsUp/>*/}
        {/*        </li>*/}
        {/*        <li className={`cursor-pointer flex justify-center items-center w-8 h-8 bg-greyColor2 rounded text-white hover:text-primaryColor1 ${message.dislike && `active`}`}>*/}
        {/*            <FaThumbsDown/>*/}
        {/*        </li>*/}
        {/*    </ul>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default CardItem;
