import CardItems from "./CardItems";

const Cardss = ({messages}) => {
    return (
        <div className="flex flex-col bg-white rounded-lg overflow-hidden">
            {messages.map((message, index) => <CardItems key={index} message={message}/>)}
        </div>
    );
};

export default Cardss;
