import React from "react";
import {useAuthProviderContext} from '../../Providers/AuthProvider'
import Skeleton from 'react-loading-skeleton'

const UserBlock = () => {
    // const {auth: {user: {first_name, last_name, email}}} = useAuthProviderContext();
    const {auth} = useAuthProviderContext();
    // console.log('[userBlock]: in userBlock', auth);

    if(!auth.user) return <Skeleton />

    const {first_name, last_name, email} = auth.user;

    return (
        <div className='flex flex-col items-center mb-6'>
            <img src={`/img/users/default-user-icon.jpg`} className='rounded-full w-24 mb-2' alt={`${first_name} ${last_name}`}/>
            <p className='text-white text-[14px] leading-[21px] font-medium'>{first_name} {last_name}</p>
            <p className='text-greyColor1 text-[13px] leading-[21px] font-medium'>{email}</p>
        </div>
    );
};

export default UserBlock;
