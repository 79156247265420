import React, {useState} from "react";
import {useNavigate, Link, useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {object, string} from "yup";
import Logo from "../../Components/Logo";
import Button from "../../Components/Button";
import FormField from "../../Components/Forms/FormField";
import {resetPasswd} from "../../services/auth";

const schema = object({
    password: string().required('Password is required'),
    password2: string().required('Confirm Password is required')
}).required();

const ResetPassword = () => {

    const [submitBtnloading, setSubmitBtnloading] = useState(false);
    const [apiResponse, setApiResponse] = useState({
        text: '',
        cls: 'text-primaryColor4'
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const key = searchParams.get("key");
    // console.log('key',key);
    // console.log('searchParams',searchParams);
    // searchParams.get("__firebase_request_key")

    const navigate = useNavigate();

    const {register, handleSubmit, formState: {errors}} = useForm({
        resolver: yupResolver(schema)
    });

    const resetPwd = async (data) => {
        setSubmitBtnloading(true);
        data = {...data, key};
        // console.log(data);
        if(data.password == data.password2){
        try {
                const res = await resetPasswd(data);
            console.log(res);
                if (res.data.success) {
                    setSubmitBtnloading(false);
                    setApiResponse({
                        text: 'Password reset successfully',
                        cls: 'text-primaryColor1'
                    })
                    navigate('/');
                } else {
                    console.error('Invalid login')
                }
        } catch (err) {
            console.error(err)
        }
        }else{
            setSubmitBtnloading(false);
            setApiResponse({
                text: 'Confirm password did not match',
                cls: 'text-primaryColor4'
            })
        }

    };

    return (
        <React.Fragment>
            <Logo/>
            <form className='mt-8 flex flex-col space-y-5' onSubmit={handleSubmit((data) => {
                resetPwd(data);
            })}>
                <FormField errors={errors} register={register} id='password' text='Password' type='password'/>
                <FormField errors={errors} register={register} id='password2' text='Password' type='password'/>
                <p className={`font-bold ${apiResponse.cls}`}>{apiResponse.text}</p>
                <Button text='Reset Password' submitBtnloading={{loading: submitBtnloading, text: 'Resetting Password'}}/>
            </form>
        </React.Fragment>
    );
};

export default ResetPassword;
