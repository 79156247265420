import React from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {object, string} from "yup";
import Logo from "../../Components/Logo";
import Button from "../../Components/Button";
import FormField from "../../Components/Forms/FormField";

const schema = object({
    whichCompanyAreYouFrom: string().required('Company name is required').min(3, 'Minimum 3 letters are required'),
    whatIsYourJobTitle: string().required('Job title is required').min(3, 'Minimum 3 letters are required'),
}).required();

const Onboard = () => {
    const {register, handleSubmit, formState: {errors}} = useForm({
        resolver: yupResolver(schema)
    });

    return (
        <React.Fragment>
            <Logo/>
            <h3 className='text-[20px] leading-[30px] font-bold mb-1 md:text-[32px] md:leading-8'>Tell us about
                yourself</h3>
            <div
                className='rounded text-[14px] leading-[22px] mt-6 font-normal text-[#664d03] border border-solid border-[#ffecb5] p-4 bg-[#fff3cd]'>We
                will use this information so that you don't have to type your Designation & Company everytime you
                generate content
            </div>
            <form className='mt-8 flex flex-col space-y-5' onSubmit={handleSubmit((data) => console.log(data))}>
                <FormField errors={errors} register={register} id='whichCompanyAreYouFrom'
                           text='Which company are you from?' value='Acme Inc'/>
                <FormField errors={errors} register={register} id='whatIsYourJobTitle' text='What is your job title?' value='Founder'/>
                <Button text='Start Generating Content'/>
            </form>
        </React.Fragment>
    );
};

export default Onboard;
