import React, {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {object, string} from "yup";
import {useNavigate} from "react-router-dom";
import {fnAddUserToDrip} from "../../utils/global";

import {confirmAlert} from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import Breadcrumbs from "../../Components/Breadcrumbs";
import Button from "../../Components/Button";
import FormField from "../../Components/Forms/FormField";

import generateContentUsingReceivers from "../../Data/SelectOptions/GenerateContentUsingReceivers";
import whatIsLengthOfTheMessage from "../../Data/SelectOptions/WhatIsLengthOfTheMessage";
import whatIsTheCallToAction from "../../Data/SelectOptions/WhatIsTheCallToAction";
import whatIsToneOfTheMessage from "../../Data/SelectOptions/WhatIsToneOfTheMessage";
import {handleGenerateContent} from "../../services/content";
import {useAuthProviderContext} from "../../Providers/AuthProvider";
import {usageInfo} from "../../services/user";
import Cardss from "../../Components/Cards/Cardss";
import Cards from "../../Components/Cards/Cards";

const schema = object({
    sender: string().required('This field is required').min(3, 'Minimum 3 letters are required').max(200, 'Maximum 200 letters allowed'),
    receiver: string().required('This field is required').min(3, 'Minimum 3 letters are required').max(200, 'Maximum 200 letters allowed'),
    meetingContext: string().required('This field is required').min(3, 'Minimum 3 letters are required').max(200, 'Maximum 200 letters allowed'),
    callToAction: string().required('This field is required'),
    tone: string().required('This field is required'),
    contentLength: string().required('This field is required'),
    template: string(),
    recommendor: string(),
    content: string().required('This field is required').min(3, 'Minimum 3 letters are required').max(2600, 'Maximum 2600 letters allowed')
}).required();

const Home = () => {

    const {register, handleSubmit, formState: {errors}, watch, setValue} = useForm({
        resolver: yupResolver(schema)
    });

    const navigate = useNavigate();
    const [recommenderBox, setRecommenderBox] = useState(false);
    const [linkedinReceiverText, setLinkedinReceiverText] = useState('LinkedIn Bio of receiver');
    const [contentGenerated, setContentGenerated] = useState([]);
    const [submitBtnloading, setSubmitBtnloading] = useState(false);
    const [contentType, setContentType] = useState('');
    const [apiResponse, setApiResponse] = useState({
        text: '',
        cls: 'text-primaryColor4'
    });
    const [showUpgrade, setShowUpgrade] = useState(process.env.NODE_ENV==='development' ? false : true);
    const [labelCount, setLabelCount] = useState({
        sender: 0,
        receiver: 0,
        meetingContext: 0,
        content: 0,
    });
    // console.log('length',(watch(["sender"])[0]).length);


    const {auth, setAuth} = useAuthProviderContext();
    const {user} = auth;
    // console.log('[Home]',user)

    useEffect(() => {
        console.log(auth.user);
        if (auth.user) {
          try {
            (async () => {
              const res = await usageInfo();
              console.log(res);
              if (res.status == 200) {
                if (res.data.expired && showUpgrade) {
                  fnAddUserToDrip();
                  confirmAlert({
                    title: "",
                    message:
                      "You have exhausted your credits. Please upgrade your plan now",
                    buttons: [
                      {
                        label: "Pay Now",
                        onClick: async () => {
                          navigate("/payment");
                        },
                      },
                      {
                        label: "Cancel",
                                        onClick: () => {
                                        },
                      },
                    ],
                  });
                } else if (res.data.expiringSoon && showUpgrade) {
                  confirmAlert({
                    title: "",
                    message:
                      "Your credits are going to be finished. Please upgrade your plan now",
                    buttons: [
                      {
                        label: "Pay Now",
                        onClick: async () => {
                          navigate("/payment");
                        },
                      },
                      {
                        label: "Cancel",
                                        onClick: () => {
                                        },
                      },
                    ],
                  });
                }
                setShowUpgrade(false);
              }
            })();
          } catch (error) {
            console.log(error);
          }
        }
      }, []);

    // let phSender = '';
    let phSender = user.first_name;

    // if (user.company != "" || user.company !== null) phSender += ', ' + user.designation + ' of ' + user.company;

    // const [contentType, setContentType] = useState('outreach');

    const generateContent = async (data) => {
        setSubmitBtnloading(true);
        data = {...data, from: ""};
        //console.log(data);
        try {
            const res = await handleGenerateContent(data);
            console.log("content response");
            console.log(res);
            if (res.data.success) {
                setSubmitBtnloading(false);
                setApiResponse({
                    text: 'Content generated successfully',
                    cls: 'text-primaryColor1'
                })
                setContentGenerated([{
                    'response': res.data.message,
                    'created_at': res.data.created_at,
                    'id': res.data.id,
                }, ...contentGenerated])
                window.scrollTo({left: 0, bottom: 0, behavior: "smooth"});
                console.log(contentGenerated);
                // setAuth(prev => ({...prev, user: {...user, data}}))
            } else {
                setSubmitBtnloading(false);
                setApiResponse({
                    text: 'Something went wrong, please try again later!',
                    cls: 'text-primaryColor4'
                })
            }
        } catch (err) {
          setSubmitBtnloading(false);
            setApiResponse({
                text: 'Character limit exceeded',
                cls: 'text-primaryColor4'
            })
            console.error(err)
        }
    };

    const updateRecommender = e => {
        console.log(e.target.value);
        if (e.target.value == 'li_bio_outreach') {
            setLinkedinReceiverText('LinkedIn Bio of receiver');
            setRecommenderBox(false)
        }
        if (e.target.value == 'li_recommendation_outreach') {
            setLinkedinReceiverText('LinkedIn Recommendation of receiver');
            setRecommenderBox(true)
        }
        if (e.target.value == 'li_post_outreach') {
            setLinkedinReceiverText('LinkedIn Post of receiver');
            setRecommenderBox(false)
        }
    }

    useEffect(() => {
        setLabelCount({...labelCount, sender: watch("sender").length});
        const subscription = watch((value, {name, type}) => {
                setLabelCount({
                    sender: watch("sender").length,
                    receiver: watch("receiver").length,
                    meetingContext: watch("meetingContext").length,
                    content: watch("content").length,
                });
            }
        )
        return () => subscription.unsubscribe()
    }, [watch]);

    return (
        <React.Fragment>
            <div className='bg-white'>
                <Breadcrumbs pageTitle='Home' title='LinkedIn AI Outreach Generator'/>
            </div>
            <div className='bg-greyColor3 py-6 px-6 md:py-11 md:px-14'>
                <form
                    className='space-y-5 bg-white rounded-lg max-w-[800px] px-6 py-6 mb-6 md:space-y-7 md:px-10 md:py-14 md:mb-10'
                    onSubmit={handleSubmit(generateContent)}>
                    <FormField errors={errors} register={register} id='sender'
                               text='Who is sending?' placeholder='John, Founder of OutreachAI'
                               value={phSender}
                               wordCount={{min: labelCount.sender, max: 200}}/>
                    <FormField errors={errors} register={register} id='receiver'
                               text='Who will receive this message?' placeholder='Elon, Founder of Tesla'
                               wordCount={{min: labelCount.receiver, max: 200}}/>
                    <FormField errors={errors} register={register} id='meetingContext'
                               text='What will be the context of meeting?' placeholder='Hiring Advice'
                               wordCount={{min: labelCount.meetingContext, max: 200}}/>
                    <FormField errors={errors} register={register} id='callToAction'
                               text='What is the call to action?' fieldType='select'
                               options={whatIsTheCallToAction}/>
                    <FormField errors={errors} register={register} id='tone'
                               text="What is tone of the message" fieldType='select'
                               options={whatIsToneOfTheMessage}/>
                    <FormField errors={errors} register={register} id='contentLength'
                               text="What is length of the message" fieldType='select'
                               options={whatIsLengthOfTheMessage}/>
                    <FormField errors={errors} register={register} id='template'
                               text="Generate content using receiver's" fieldType='select'
                               options={generateContentUsingReceivers} changeHandler={updateRecommender}/>
                    <input type="hidden" {...register('contentType')} />
                    {/*<div className='flex flex-col'>*/}
                    {/*    <label htmlFor="template"*/}
                    {/*           className="mb-1 text-[13px] leading-[18px] font-medium md:mb-1.5 md:text-[14px] md:leading-[21px]">Generate*/}
                    {/*        content using receiver's</label>*/}
                    {/*    <select onChange={updateRecommender}*/}
                    {/*            className="w-full px-3 py-1.5 text-[13px] leading-[20px] bg-white border text-greyColor5 border-solid border-greyColor4 rounded-md md:text-[16px] md:leading-6 undefined"*/}
                    {/*            id="template" aria-label="Generate content using receiver's" name='template' register={register}>*/}
                    {/*        <option value="li_bio_outreach">LinkedIn Bio</option>*/}
                    {/*        <option value="li_recommendation_outreach">LinkedIn Recommendation</option>*/}
                    {/*        <option value="li_post_outreach">LinkedIn Post</option>*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                    {recommenderBox && (
                    <FormField errors={errors} register={register} id='recommendor'
                                   text='Who wrote the recommendation?' placeholder='Bill, Founder of Microsoft'/>
                    )}
                    <FormField errors={errors} register={register} id='content' value=''
                               text={linkedinReceiverText} fieldType='textarea' rows={7}
                               wordCount={{min: labelCount.content, max: 2600}}/>
                    <div className='flex flex-col items-end gap-2'>
                        <p className={`font-bold ${apiResponse.cls}`}>{apiResponse.text}</p>
                    <div className='flex justify-end gap-2'>
                            <Button clickHandler={() => setValue("contentType", "icebreaker")}
                                    text='Generate Icebreaker' width='w-auto'
                                    submitBtnloading={{loading: submitBtnloading, text: 'Generating Icebreaker'}}/>
                            <Button clickHandler={() => setValue("contentType", "outreach")} text='Generate Message'
                                    width='w-auto'
                                    submitBtnloading={{loading: submitBtnloading, text: 'Generating Message'}}/>
                        </div>
                    </div>
                </form>
            </div>
            {contentGenerated.length > 0 && (
                <div className='bg-greyColor3 py-6 px-6 md:pb-11 md:px-14'>
                    <h2 className='font-bold text-[24px] leading-[32px] mb-5 md:text-[48px] md:leading-[48px]'>Generated
                        Content</h2>
                    <div className='bg-white rounded-lg max-w-[800px]'>
                        <Cardss messages={contentGenerated}/>
                        {/*<Cards messages={contentGenerated} page="history"/>*/}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default Home;