import React, { useState, useEffect } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useAuthProviderContext } from "../../Providers/AuthProvider";
import { axiosInterceptor } from "../../AxiosInstance";
import { getProfile } from "../../services/auth";
import {
  paymentMethod,
  mUpdateCard,
  refund,
  paymentFailed,
  subscribe,
  changePlan,
  cancelPlan,
} from "../../services/payment";

export default function PaymentForm() {
  const [updateData, setUpdateData] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [cardType, setCardType] = useState("");
  const [cardLast4, setCardLast4] = useState("");
  const [isPaymentSave, setIsPaymentSave] = useState(false);
  const [updateCard, setUpdateCard] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [planId, setPlanId] = useState("");
  const [currentPlan, setCurrentPlan] = useState("");
  const [updatePlan, setUpdatePlan] = useState(false);
  const [refferal_id, setRefferal_Id] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  //let { data, error, mutate } = useSWR(`/api/profile`, fetcher);

  const updateDataFromDatabase = () => {
    console.log("updateDataFromDatabase");
    setUpdateData(true);
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     console.log("interval called");
  //     setUpdateCard(false);
  //     setIsPaymentSave(false);
  //   }, 2000);

  //   return () => {
  //     clearTimeout(intervalId);
  //   };
  // }, []);

  const { auth } = useAuthProviderContext();

  

  // useEffect(() => {
  //   console.log("useeffect called");
  //   // setSpinner(true);
  //   // (async () => {
  //   //   await axiosInterceptor(auth.token);
  //   //   try {
  //   //     const res = await getProfile();
  //   //     console.log(res.data.userInfo);
  //   //     const data = res.data;
  //   //     console.log("updating data....");
  //   //     console.log(data);
  //   //     setName(data.userInfo.first_name + " " + data.userInfo.last_name);
  //   //     setEmail(data.userInfo.email);
  //   //     setCurrentPlan(data.userInfo.current_plan);
  //   //     setRefferal_Id(data.userInfo.refferal_id);
  //   //     setPlanId(data.userInfo.paid_plan_id);
  //   //     (async () => {
  //   //       try {
  //   //         setSpinner(true);
  //   //         await axiosInterceptor(auth.token);
  //   //         const res = await paymentMethod();
  //   //         const data = res.data;
  //   //         console.log(data);
  //   //         if (data.success) {
  //   //           setIsPaymentSave(true);
  //   //           setCardType(data.paymentMethod.card.brand);
  //   //           setCardLast4(data.paymentMethod.card.last4);
  //   //         }
  //   //         console.log(isPaymentSave);
  //   //         console.log(cardLast4);
  //   //         setSpinner(false);
  //   //         //console.log(data);
  //   //       } catch (err) {
  //   //         console.log("Error occured when fetching ");
  //   //         setSpinner(false);
  //   //       }
  //   //     })();
  //   //   } catch (err) {
  //   //     console.error(err);
  //   //   }
  //   // })();
  //   // setSpinner(false);
  // }, []);

  useEffect(() => {
    console.log("update data");
    console.log("spinner: " + spinner);
    const timer = setTimeout(() => {
      setSpinner(true);
      (async () => {
        await axiosInterceptor(auth.token);
        try {
          const res = await getProfile();
          console.log(res.data.userInfo);
          const data = res.data;
          console.log("updateCard: " + updateCard);
          if (!updateCard) {
            console.log("updating data....");
            console.log(data);
            setName(data.userInfo.first_name + " " + data.userInfo.last_name);
            setEmail(data.userInfo.email);
            setCurrentPlan(data.userInfo.current_plan);
            setRefferal_Id(data.userInfo.refferal_id);
            setPlanId(data.userInfo.paid_plan_id);
            (async () => {
              try {
                setSpinner(true);
                await axiosInterceptor(auth.token);
                const res = await paymentMethod();
                const data = res.data;
                console.log(data);
                if (data.success) {
                  setIsPaymentSave(true);
                  setCardType(data.paymentMethod.card.brand);
                  setCardLast4(data.paymentMethod.card.last4);
                }
                console.log(isPaymentSave);
                console.log(cardLast4);
                setSpinner(false);
                //console.log(data);
              } catch (err) {
                console.log("Error occured when fetching ");
                setSpinner(false);
              }
            })();
          }
        } catch (err) {
          console.error(err);
        }
        setUpdateData(false);
      })();
    }, 4000);
    //setSpinner(false);
    setUpdateData(false);
    return () => {
      clearTimeout(timer);
    };
  }, [updateData]);

  const createSubscription = async () => {
    try {
      setLoader(true);
      //create a payment method
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("card"),
      });

      let response;

      if (updateCard) {
        confirmAlert({
          title: "Confirm to charge",
          message:
            "We will initiate a temporary authorization of $0.50 to verify your card. This amount will be refunded to you within a 7-day period.",
          buttons: [
            {
              label: "Confirm Charge",
              onClick: async () => {
                //update card
                await axiosInterceptor(auth.token);
                let response = await mUpdateCard({
                  paymentMethod: paymentMethod.paymentMethod.id,
                });
                // response = await fetch("/api/payments/updateCard", {
                //   method: "POST",
                //   headers: {
                //     "Content-Type": "application/json",
                //   },
                //   body: JSON.stringify({
                //     paymentMethod: paymentMethod.paymentMethod.id,
                //   }),
                // });
                console.log(response);
                const data = response.data;
                if (response.status !== 200) {
                  setLoader(false);
                  setUpdateCard(false);
                  setIsPaymentSave(false);
                  updateDataFromDatabase();
                  return notify("Payment Unsuccessful, " + data.err);
                }
                const confirm = await stripe.confirmCardPayment(
                  data.clientSecret
                );
                if (confirm.error) {
                  setUpdateCard(false);
                  //setLoader(false);
                  await axiosInterceptor(auth.token);
                  response = await paymentFailed({
                    paymentMethod: paymentMethod.paymentMethod.id,
                  });
                  //   response = await fetch("/api/payments/paymentFailed", {
                  //     method: "POST",
                  //     headers: {
                  //       "Content-Type": "application/json",
                  //     },
                  //     body: JSON.stringify({
                  //       paymentMethod: paymentMethod.paymentMethod.id,
                  //     }),
                  //   });
                  setIsPaymentSave(false);
                  setCurrentPlan("Starter");
                  setLoader(false);
                  setIsChecked(false);
                  updateDataFromDatabase();
                  return notify("Payment Unsuccesfull");
                }
                await axiosInterceptor(auth.token);
                const res = await refund({
                  paymentIntent: data.paymentIntent,
                });
                // const res = await fetch("/api/payments/refund", {
                //   method: "POST",
                //   headers: {
                //     "Content-Type": "application/json",
                //   },
                //   body: JSON.stringify({
                //     paymentIntent: data.paymentIntent,
                //   }),
                // });
                updateDataFromDatabase();
                return notify("Card Updated!");
              },
            },
            {
              label: "No, I don't accept",
              onClick: () => {
                setIsPaymentSave(true);
                setLoader(false);
              },
            },
          ],
        });
        setIsPaymentSave(true);
        setLoader(false);
        setUpdateCard(false);
        setUpdatePlan(false);
        setUpdateCard(false);
        updateDataFromDatabase();
      } else {
        await axiosInterceptor(auth.token);

        response = await subscribe({
          name,
          email,
          paymentMethod: paymentMethod.paymentMethod.id,
          selectedPlan: selectedPlan,
          refferal_id: refferal_id,
        });
        console.log(response.data.userInfo);
        const data = response.data;

        //subscribe new customer
        // response = await fetch("/api/payments/subscribe", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     name,
        //     email,
        //     paymentMethod: paymentMethod.paymentMethod.id,
        //     selectedPlan: selectedPlan,
        //     refferal_id: refferal_id,
        //   }),
        // });
        //const data = await response.json();
        //console.log(data);
        if (response.status !== 200) {
          setIsChecked(false);
          setLoader(false);
          setUpdateCard(false);
          setIsPaymentSave(false);
          updateDataFromDatabase();
          return notify("Payment Unsuccessful, " + data.err);
        }
        const confirm = await stripe.confirmCardPayment(data.clientSecret);
        if (confirm.error) {
          setIsChecked(false);
          setIsPaymentSave(false);
          setCurrentPlan("Starter");

          await axiosInterceptor(auth.token);
          response = await paymentFailed({
            paymentMethod: paymentMethod.paymentMethod.id,
          });

          //   response = await fetch("/api/payments/paymentFailed", {
          //     method: "POST",
          //     headers: {
          //       "Content-Type": "application/json",
          //     },
          //     body: JSON.stringify({
          //       paymentMethod: paymentMethod.paymentMethod.id,
          //     }),
          //   });
          setLoader(false);
          setIsChecked(false);
          updateDataFromDatabase();
          return notify("Payment Unsuccesfull");
        }
        notify("Payment Successful! Subsciption Active");
        // response = await fetch("/api/payments/updatePlan", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     selectedPlan: selectedPlan,
        //   }),
        // });
        setIsChecked(false);
        setIsPaymentSave(true);
        setLoader(false);
        setUpdatePlan(false);
        updateDataFromDatabase();
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
      setSpinner(false);
      notify("Payment Failed: " + error.message);
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
  };

  const handlePaymentChange = () => {
    setUpdateCard(true);
    setIsPaymentSave(false);
  };

  const notify = (label) => toast(label);

  const handleSelectedPlan = async (plan) => {
    //console.log("plan: " + plan);
    //console.log("current plan: " + currentPlan);
    if ((currentPlan !== "Starter" && currentPlan !== "") || isPaymentSave) {
      if (currentPlan === plan) {
        notify(`Already Subscribed to ${plan} Plan`);
      } else {
        setSpinner(true);
        setUpdatePlan(false);

        await axiosInterceptor(auth.token);
        let response = await changePlan({
          selectedPlan: plan,
        });

        // const response = await fetch("/api/payments/changePlan", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     selectedPlan: plan,
        //   }),
        // });
        const data = response.data;
        if (data.message === "Action") {
          const confirm = await stripe.confirmCardPayment(data.clientSecret);
          if (confirm.error) {
            let paymentMethodId;
            if (isPaymentSave) {
              paymentMethodId = 0;
            }

            await axiosInterceptor(auth.token);
            response = await paymentFailed({
              paymentMethod: paymentMethodId,
            });

            // response = await fetch("/api/payments/paymentFailed", {
            //   method: "POST",
            //   headers: {
            //     "Content-Type": "application/json",
            //   },
            //   body: JSON.stringify({
            //     paymentMethod: paymentMethodId,
            //   }),
            // });
            setSpinner(false);
            setLoader(false);
            setIsPaymentSave(false);
            setIsChecked(false);
            updateDataFromDatabase();
            return notify("Payment Unsuccesfull");
          }
        }
        notify("Plan Changed!");
        setCurrentPlan(plan);
        setSpinner(false);
        setLoader(false);
        setIsChecked(false);
        updateDataFromDatabase();
      }
    } else if (
      currentPlan === "Starter" ||
      isPaymentSave ||
      currentPlan === ""
    ) {
      setSelectedPlan(plan);
    }
  };

  const handlePlanChange = () => {
    setUpdatePlan(true);
    setIsPaymentSave(false);
  };

  const handleCancelPlan = async () => {
    confirmAlert({
      title: "Confirm to Cancel",
      message: "Are you sure you want to cancel your current plan.",
      buttons: [
        {
          label: "Confirm",
          onClick: async () => {
            if (currentPlan !== "Starter") {
              setSpinner(true);
              await axiosInterceptor(auth.token);
              const response = await cancelPlan();
              //   const response = await fetch("/api/payments/cancelPlan", {
              //     method: "GET",
              //     headers: {
              //       "Content-Type": "application/json",
              //     },
              //   });

              if (response.status === 200) {
                notify("Plan Cancelled!");
                setUpdateCard(false);
                setIsPaymentSave(false);
                //setCurrentPlan("Starter");
                updateDataFromDatabase();
              } else {
                notify("No Plans to Cancel");
              }
              setSpinner(false);
            } else {
              notify("No Plans to Cancel");
            }
          },
        },
        {
          label: "Reject",
          onClick: () => {},
        },
      ],
    });
  };

  const toggleTerm = (e) => {
    setIsChecked((prevValue) => {
      //console.log(!prevValue);
      return !prevValue;
    });
  };

  const packagePrice = () => {
    let price = "";
    if (selectedPlan === "Pro-(Monthly)" && !isChecked) {
      price = "$9.99";
    } else if (selectedPlan === "Growth-(Monthly)" && !isChecked) {
      price = "$39.99";
    } else if (selectedPlan === "Pro-(Annually)" && isChecked) {
      price = "$119.88";
    } else if (selectedPlan === "Growth-(Annually)" && isChecked) {
      price = "$479.88";
    }
    return price;
  };

  const showCardElement = () => {
    const cardElementHtml = (
      <React.Fragment>
        <div className="mt-10 w-2/3 mx-auto">
          <CardElement onChange={handleChange} />
        </div>
        <br />
        <div className="px-4 py-3 bg-gray-50 text-center sm:px-6">
          {loader === false && (
            <button
              disabled={disabled}
              onClick={createSubscription}
              type="button"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
            >
              {updateCard
                ? "Update Card"
                : `Upgrade to ${selectedPlan} Plan - ${packagePrice()}`}
            </button>
          )}

          {loader === true && (
            <button
              type="button"
              className="w-full bg-teal-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
            >
              <svg
                className="animate-spin ml-3 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Updating....
            </button>
          )}
        </div>
      </React.Fragment>
    );
    if (updateCard && !isPaymentSave && !spinner) {
      return cardElementHtml;
    } else if (
      updatePlan &&
      selectedPlan !== "" &&
      !isPaymentSave &&
      !spinner
    ) {
      return cardElementHtml;
    }
  };

  // if (cardType != "" && cardLast4 != ""){
  //   setIsPaymentSave(false);
  // }

  const current_plan = () => {
    let plan = "";
    if (
      currentPlan !== "" ||
      currentPlan !== undefined ||
      currentPlan !== "Starter"
    ) {
      plan = currentPlan.split("-")[0];
    } else {
      plan = "Starter";
    }
    return plan;
  };

  return (
    <React.Fragment>
      <ToastContainer autoClose={3000} />
      <form action="#" method="POST">
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-3">
            <label
              htmlFor="company-website"
              className="block text-md font-medium text-gray-700"
            >
              Name on Card
              <span className="text-xs ml-1 text-gray-500"></span>
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                value={name}
                onChange={(e) => {}}
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-300 bg-gray-200"
                placeholder="Name on Card"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-6 mt-5">
          <div className="col-span-3">
            <label
              htmlFor="company-website"
              className="block text-md font-medium text-gray-700"
            >
              Email
              <span className="text-xs ml-1 text-gray-500"></span>
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                value={email}
                onChange={(e) => {}}
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-gray-300 bg-gray-200"
                placeholder="Email"
              />
            </div>
          </div>
        </div>
        <br />
        {spinner && (
          <React.Fragment>
            <div className="flex flex-row items-center">
              <svg
                className="animate-spin ml-3 mr-3 h-10 w-10 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span>Loading..... Please Wait!</span>
            </div>
          </React.Fragment>
        )}
        {!spinner && (
          <React.Fragment>
            <div className="flex flex-col items-center">
              <div>
                <h5 className="mt-4 mb-4 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                  {"Current Plan: " + current_plan()}
                </h5>
              </div>
              <div className="mb-4">
                <button
                  onClick={handleCancelPlan}
                  type="button"
                  className="float-right bg-teal-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                >
                  Cancel Plan
                </button>
                <button
                  onClick={handlePlanChange}
                  type="button"
                  className="mr-3 float-right bg-teal-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                >
                  Update Plan
                </button>
              </div>
            </div>
          </React.Fragment>
        )}
        {updatePlan && !spinner && (
          <React.Fragment>
            {/* <div className="flex justify-center items-center">
              <span class="mr-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                Monthly
              </span>
              <label class="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  class="sr-only peer"
                  onChange={toggleTerm}
                />
                <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-100 dark:peer-focus:ring-green-100 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#008074]"></div>
                <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Annually
                </span>
              </label>
            </div> */}
            {/* {isChecked && (
              <div className="flex flex-row justify-center mt-5">
                <div className="w-1/2 block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 mr-10">
                  <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                    Pro
                  </h5>
                  <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                    5,000 Characters Limit
                  </p>
                  <button
                    onClick={handleSelectedPlan.bind(null, "Pro-(Annually)")}
                    type="button"
                    className="inline-block rounded bg-teal-600 px-6 pb-2 pt-2.5 text-md font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  >
                    $119.88
                  </button>
                </div>
                <div className="w-1/2 block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                  <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                    Growth
                  </h5>
                  <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                    Unlimited Characters
                  </p>
                  <button
                    onClick={handleSelectedPlan.bind(null, "Growth-(Annually)")}
                    type="button"
                    className="inline-block rounded bg-teal-600 px-6 pb-2 pt-2.5 text-md font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  >
                    $479.88
                  </button>
                </div>
              </div>
            )} */}
            {!isChecked && (
              <div className="flex flex-row justify-center mt-5">
                <div className="w-1/2 block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 mr-10">
                  <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                    Growth
                  </h5>
                  <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                    800 Lead Creations
                  </p>
                  <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                    Unlimited Web Application Access
                  </p>
                  <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                    Unlimited Sequencing
                  </p>
                  <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                    Unlimited AI Usage for Messaging
                  </p>
                  <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                    Unlimited Direct Support Access
                  </p>
                  <button
                    onClick={handleSelectedPlan.bind(null, "Growth")}
                    type="button"
                    className="inline-block rounded bg-teal-600 px-6 pb-2 pt-2.5 text-md font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  >
                    $39.99
                  </button>
                </div>
                <div className="w-1/2 block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                  <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                    Pro
                  </h5>
                  <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                    100 Lead Creations
                  </p>
                  <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                    Web Application Access
                  </p>
                  <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                    Unlimited Sequencing
                  </p>
                  <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                    Unlimited AI Usage for Messaging
                  </p>
                  <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                    Direct Support Access
                  </p>
                  <button
                    onClick={handleSelectedPlan.bind(null, "Pro")}
                    type="button"
                    className="inline-block rounded bg-teal-600 px-6 pb-2 pt-2.5 text-md font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  >
                    $9.99
                  </button>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        {isPaymentSave && !spinner && cardLast4 != "" && (
          <React.Fragment>
            <div className="flex flex-row bg-gray-100 items-center mt-10 py-4">
              <img
                alt="card logo"
                src={require(`./../../assets/img/payment-methods/${cardType}-logo.png`)}
                style={{ width: "20%" }}
              />
              <span>
                We'll charge the card ending{" "}
                <span className="text-2xl">{cardLast4}</span> that we have on
                file
              </span>
              <a
                onClick={handlePaymentChange}
                href="#"
                className="flex grow justify-center items-center"
              >
                <span className="text-blue-800 text-xl">Change</span>
              </a>
            </div>
          </React.Fragment>
        )}
        {showCardElement()}
      </form>
    </React.Fragment>
  );
}
