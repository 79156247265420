import React from "react";
import PropTypes from "prop-types";

const Input = ({type, id, placeholder, value, register, disabled}) => {
    return (
        <input type={type} disabled={disabled}
               className={`w-full px-3 py-1.5 text-[13px] leading-[20px] border text-greyColor5 border-solid border-greyColor4 rounded-md md:text-[16px] md:leading-6 ${disabled && `disabled:bg-greyColor2 disabled:cursor-not-allowed`}`}
               placeholder={placeholder && placeholder} id={id} {...register(id, { value: value })}/>
    );
};

Input.defaultProps = {
    type: 'text'
}

Input.propTypes = {
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string
}

export default Input;
