import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {object, string} from "yup";
import Logo from "../../Components/Logo";
import Button from "../../Components/Button";
import FormField from "../../Components/Forms/FormField";
import {forgotPassword} from "../../services/auth";

const schema = object({
    email: string().email().required('Email is required')
}).required();

const ForgotPassword = () => {

    const [submitBtnloading, setSubmitBtnloading] = useState(false);
    const [apiResponse, setApiResponse] = useState({
        text: '',
        cls: 'text-primaryColor4'
    });
    const {register, handleSubmit, formState: {errors}} = useForm({
        resolver: yupResolver(schema)
    });

    const forgotPwd = async (data) => {
        setSubmitBtnloading(true);
        try {
            const res = await forgotPassword(data);
            if (res.data.success) {
                setSubmitBtnloading(false);
                setApiResponse({
                    text: 'Reset email has been sent to the provided email address',
                    cls: 'text-primaryColor1'
                })
            } else {
                setSubmitBtnloading(false);
                setApiResponse({
                    text: 'Something went wrong, please try again later!',
                    cls: 'text-primaryColor4'
                })
                console.error(res.data.message)
            }
        } catch (err) {
            console.error(err)
        }
    };

    return (
        <React.Fragment>
            <Logo/>
            <p className='text-[14px] leading-3 md:text-[14px] md:leading-6'>Enter your email address associated with account and we’ll send you a link to reset your password.</p>
            <form className='mt-8 flex flex-col space-y-5' onSubmit={handleSubmit((data) => {
                forgotPwd(data);
            })}>
                <FormField errors={errors} register={register} id='email' placeholder='Email'/>
                <Button text='Continue' submitBtnloading={{loading:submitBtnloading, text: 'Sending Email'}}/>
                <p className={`font-bold ${apiResponse.cls}`}>{apiResponse.text}</p>
            </form>
        </React.Fragment>
    );
};

export default ForgotPassword;
