const WhatIsTheCallToAction = {
    defaultValue: 'Schedule Meeting',
    data: [
        {
            title: 'Schedule Meeting',
            value: 'Schedule a Meeting'
        },
        {
            title: 'Schedule Call',
            value: 'Schedule a Call'
        },
        {
            title: 'Compliment',
            value: 'Compliment'
        },
        {
            title: 'Ask Question',
            value: 'Ask a Question'
        }
    ]
}

export default WhatIsTheCallToAction