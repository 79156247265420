import { addUserToDrip } from "../services/drip";

export const fnAddUserToDrip = async () => {
  try {
    const res = await addUserToDrip();
    console.log(res);
  } catch (error) {
    console.log(error);
  }
};
