import React from "react";
import PropTypes from "prop-types";

const Button = ({
  type,
  text,
  width,
  classes,
  clickHandler,
  submitBtnloading,
}) => {
  let btn = (
    <button
      type={type}
      onClick={clickHandler}
      className={`py-2 px-6 mt-2 text-white ${classes} text-[13px] rounded-full baseline hover:bg-primaryColor2 transition-all duration-500 md:py-3 md:px-8 md:text-[16px] ${width}`}
    >
      {text}
    </button>
  );

  if (submitBtnloading.loading) {
    btn = (
      <button
        type={type}
        onClick={clickHandler}
        disabled="true"
        className={`flex justify-center items-center py-2 px-6 mt-2 text-white ${classes} text-[13px] rounded-full baseline hover:bg-primaryColor2 transition-all duration-500 md:py-3 md:px-8 md:text-[16px] disabled:cursor-not-allowed disabled:opacity-50 ${width}`}
      >
        <svg
          className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        {submitBtnloading.text}...
      </button>
    );
  }
  return btn;
};

Button.defaultProps = {
  submitBtnloading: { loading: false },
  type: "submit",
  text: "Sign in",
  width: "w-full",
  classes: "bg-primaryColor1",
};

Button.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
};

export default Button;

