import React, {useState} from "react";
import {useNavigate, Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {boolean, object, string} from "yup";
import Logo from "../../Components/Logo";
import Button from "../../Components/Button";
import FormField from "../../Components/Forms/FormField";
import {handleRegister, handleGoogleLogin} from "../../services/auth";
import IconGoogle from "../../Components/Icons/IconGoogle";

const schema = object({
    fname: string().required('The field is require').min(3, 'Minimum 3 letters are required'),
    lname: string().required('The field is require').min(3, 'Minimum 3 letters are required'),
    email: string().email().required('The field is require'),
    password: string().required('The field is require'),
    agrement: boolean().oneOf([true], "The field is must be checked").required("The field is required")
}).required();

const Register = () => {

    const [submitBtnloading, setSubmitBtnloading] = useState(false);
    const [apiResponse, setApiResponse] = useState({
        text: '',
        cls: 'text-primaryColor4'
    });

    const {register, handleSubmit, trigger, formState: {errors}} = useForm({
        resolver: yupResolver(schema)
    });

    const navigate = useNavigate();

    const signup = async (data) => {
        setSubmitBtnloading(true);
        data = {...data, from: "web", refferal_id: "", invite_accepted: true};
        console.log(data);
        try {
            const res = await handleRegister(data);
            console.log(res);
            if (res.data.success) {
                console.log('success ', res);
                navigate('/login');
            } else {
                setSubmitBtnloading(false);
                setApiResponse({
                    text: 'Something went wrong, please try again later!',
                    cls: 'text-primaryColor4'
                })
                console.error(res.data.message)
            }
        } catch (err) {
            console.error(err)
        }
    };

    const loginGoogle = async () => {

        // temp hardcode, replace or remove 

        const data = { from: "web", refferal_id: ""};
   
        try {
          const res = await handleGoogleLogin(data);
         
          if (res.data.authURL) {
            window.open(res.data.authURL, "_blank")
          } else {
            setApiResponse({
              text: "Invalid login details",
              cls: "text-primaryColor4",
            });
            console.error(res.data.message);
          }
        } catch (err) {
          console.log("TEST");
          console.log(err);
          setApiResponse({
            text: "Invalid login details",
            cls: "text-primaryColor4",
          });
          console.error(err);
        }
      };

    return (
        <React.Fragment>
            <Logo/>
            <p className='text-[14px] leading-3 md:text-[16px] md:leading-6 text-right'>Or already have an account? <Link
                className='text-primaryColor1'
                to={'/login'}>Sign in</Link></p>
            <form className='mt-2 flex flex-col' onSubmit={handleSubmit((data) => {
                signup(data);
            })}>
                <FormField errors={errors} register={register} id='fname' placeholder='First Name'/>
                <FormField errors={errors} register={register} id='lname' placeholder='Last Name'/>
                <FormField errors={errors} register={register} id='email' placeholder='Email address'/>
                <FormField errors={errors} register={register} id='password' placeholder='Password' type='password'/>
                <div className="or_divider">
                    <div className="dots_line"></div>
                    <p>OR</p>
                    <div className="dots_line"></div>
                </div>
                <div 
                    className="login_google"
                    onClick={async (e) => {
                        e.preventDefault();
                        const isValid = await trigger("agrement");
                        isValid && loginGoogle();        
                      }}
                      >
                    <IconGoogle/>
                    <button>Join with Google</button>
                </div>
                <FormField
                    errors={errors}
                    register={register}
                    customClass="agrement-checkbox"
                    id="agrement"
                    label={true}
                    text={<p>I accept the <a href="#">Terms of use</a> and <a href="#">Privacy Policy</a></p>}
                    type="checkbox"
                />
                <Button text='Create Account' submitBtnloading={{loading: submitBtnloading, text: 'Registering'}}/>
                <p className={`font-bold ${apiResponse.cls}`}>{apiResponse.text}</p>
            </form>
        </React.Fragment>
    );
};

export default Register;
