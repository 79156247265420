import React from "react";
import PropTypes from "prop-types";
import Option from "./Option";

const Select = ({id, text, options, register, disabled, changeHandler}) => {

    const {data,defaultValue} = options
    // console.log(changeHandler);
    return (
        <select disabled={disabled} onChange={(e)=>console.log(e)}
            className={`w-full px-3 py-1.5 text-[13px] leading-[20px] bg-white border text-greyColor5 border-solid border-greyColor4 rounded-md md:text-[16px] md:leading-6 ${disabled && `disabled:bg-greyColor2 disabled:cursor-not-allowed`}`}
            id={id} defaultValue={defaultValue || ''}
            aria-label={text} {...register(id,changeHandler && {
                onChange:changeHandler
                })}
        >
            {data.map((option, index) => <Option key={index} title={option.title} value={option.value}/>)}
            {/*{data.map((option, index) => <option key={index} value={option.value}>{option.title}</option>)}*/}
        </select>
    );
};

Select.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string.isRequired,
}

export default Select;