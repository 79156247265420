import React from "react";
import {createContext, useContext, useState, useEffect} from "react";
import {axiosInterceptor} from "../AxiosInstance";
import {useAuthProviderContext} from "./AuthProvider";
import {handleUpdateContent} from "../services/content";

const ContentContext = createContext(null);

export const ContentProvider = ({children}) => {

    const {auth} = useAuthProviderContext();

    const [favorites,setFavorites]=useState({
        data:[],
        isLoading:true
    })

    const [history,setHistory]=useState({
        data:[],
        isLoading:true
    })

    // useEffect(()=>{
    //     console.log(favorites)
    // },[favorites])


    const handleFav = async content_id => {
        console.log(content_id);
        await axiosInterceptor(auth.token);
        try {
            const res = await handleUpdateContent({content_id, type: 'favs'});
            console.log(res);
        } catch (err) {
            console.error(err);
        }
    }

    return <ContentContext.Provider value={{
        favorites, setFavorites, history, setHistory
    }}>{children}</ContentContext.Provider>;
};

export const useContentProviderContext = () => useContext(ContentContext);
