import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
//import Typed from "react-typed";

const Breadcrumbs = ({ pageTitle, title }) => {
  return (
    <div className="flex flex-col px-6 py-6 bg-white border-b border-solid border-greyColor2 md:px-11 md:py-8">
      <ul className="m-0 p-0 list-none flex mb-1.5 md:mb-3.5">
        <li className='mr-5 pr-4 text-[14px] leading-[20px] font-medium relative after:content-[">"] after:absolute after:top-0 after:bottom-0 after:m-auto after:right-[-8px] after:text-[20px] after:leading-[25px] after:h-[24px] after:font-normal'>
          <NavLink to="/">LinkedIn Outreach</NavLink>
        </li>
        <li className="text-[14px] leading-[20px] font-medium text-primaryColor1">
          <span className="active">{pageTitle}</span>
        </li>
      </ul>
      <h2 className='font-bold text-[24px] leading-[32px] md:text-[48px] md:leading-[48px]'>{title}</h2>
      {/* <Typed strings={[title]} typeSpeed={1} backSpeed={1} onComplete={(e) => e.cursor.remove()}
                   className='font-bold text-[24px] leading-[32px] md:text-[48px] md:leading-[48px]'/> */}
    </div>
  );
};

Breadcrumbs.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Breadcrumbs;
