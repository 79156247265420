import React from "react";

const Download = () => {
  return (
    <React.Fragment>
      {/* <div className="w-screen h-screen bg-greyColor2">
        <div className="flex flex-row w-full">
          <img
            src={`/img/logo-dark.svg`}
            class="w-[139px] h-[42px] m-auto"
            alt="Outreach AI logo"
          />
          <img
            src={`/img/sync.png`}
            class="w-[25px] h-[25px] m-auto"
            alt="Likedin logo"
          />
          <img
            src={`/img/linkedin_logo.png`}
            class="w-[35px] h-[35px] m-auto"
            alt="Likedin logo"
          />
        </div>
        <div className="flex-row w-full"></div>
      </div> */}
      <div className="flex flex-col h-[100vh] bg-greyColor2">
        <div className="flex flex-row">
          <div className="flex-none w-14"></div>
          <div className="flex grow pt-10 justify-center h-100">
            <div className="flex-col">
              <div className="flex justify-center text-3xl font-bold text-primaryColor1">
                Next: Install the Extension
              </div>
              <div className="flex flex-row py-8 pr-[15%] pl-[15%]">
                <img
                  src={`/img/logo-dark.svg`}
                  class="w-[185px] h-[56px] m-auto"
                  alt="Outreach AI logo"
                />
                <img
                  src={`/img/sync.png`}
                  class="w-[25px] h-[25px] m-auto"
                  alt="Likedin logo"
                />
                <img
                  src={`/img/linkedin_logo.png`}
                  class="w-[50px] h-[50px] m-auto"
                  alt="Likedin logo"
                />
              </div>
              <div className="flex justify-center">
                {" "}
                Install the extension to start generating leads on LinkedIn using OutreachAI
              </div>
              <div className="mt-[-100px] my-[-50px]">
                <img
                  src={`/img/outreach.png`}
                  class="w-[512px] h-[512px]"
                  alt="Outreach AI"
                />
              </div>
              <div className="flex justify-center ">
                <button
                  className="rounded-full w-[300px] bg-primaryColor1 px-5 py-3 text-lg font-medium text-white transition duration-200 hover:bg-primaryColor2 active:bg-primaryColor2 dark:bg-primaryColor2 dark:text-white dark:hover:bg-primaryColor2 dark:active:bg-primaryColor2"
                  onClick={() => {
                    window.location.href =
                      "https://chrome.google.com/webstore/detail/outreachai/dflaihboigogikfpkgkoniicfoojcfbc";
                  }}
                >
                  Add to Chrome
                </button>
              </div>
              <div className="mt-2">
                <img
                  src={`/img/rating.png`}
                  class="w-[200px] h-[35px] m-auto"
                  alt="Likedin logo"
                />
              </div>
            </div>
          </div>
          <div className="flex-none w-14"></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Download;
