import React from "react";
import PropTypes from "prop-types";

const Logo = ({logo}) => {
    return (
        <div className="logo w-full mb-[28px]">
            <img src={`/img/${logo}`} class="w-[139px] h-[42px] m-auto" alt="Outreach AI logo"/>
        </div>
    );
};

Logo.defaultProps = {
    logo: 'logo-dark.svg'
}

Logo.propTypes = {
    logo: PropTypes.string.isRequired
}

export default Logo;
