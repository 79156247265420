import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const LinkButton = ({ text, width, classes, target, link, icon }) => {
  return (
    <Link
      className={`py-2 px-6 ${width} flex items-center gap-1.5 text-white bg-${classes} text-[13px] rounded-full baseline hover:bg-primaryColor2 transition-all duration-500 md:py-2.5`}
      target={target}
      to={link}
    >
      {text} {icon}
    </Link>
  );
};

LinkButton.defaultProps = {
  width: "w-auto",
  classes: "primaryColor1",
};

LinkButton.propTypes = {
  text: PropTypes.string,
  target: PropTypes.string,
  icon: PropTypes.element,
  width: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
};

export default LinkButton;
