import React from "react";
import LinkButton from "../Button/LinkButton";
import {FaHome} from 'react-icons/fa'
import {TbMoodSadDizzy} from 'react-icons/tb';

const PageNotFound = () => {
    return (
        <div className='flex flex-col px-5 items-center justify-center min-h-screen space-y-4 md:space-y-6'>
            <TbMoodSadDizzy size={150}/>
            <h2 className='text-3xl font-bold text-primaryColor3 md:text-5xl'>Page not found</h2>
            <p className='text-1xl text-primaryColor2 text-center md:text-2xl'>Uh oh, we can't seem to find the page
                you're looking for!</p>
            <LinkButton text='Back to Home'
                        link='/'
                        icon={<FaHome/>}/>
        </div>
    );
};

export default PageNotFound;
