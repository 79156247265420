import React from "react";
import Label from "./Label";
import Input from "./Fields/Input";
import Select from "./Fields/Select";
import TextArea from "./Fields/TextArea";
import PropTypes from "prop-types";
import FormError from "./FormError";

const FormField = ({
  label,
  type,
  fieldType,
  id,
  text,
  placeholder,
  required,
  wordCount,
  ariaDescribedby,
  options,
  rows,
  value,
  register,
  errors = "",
  changeHandler,
  disabled,
  customClass
}) => {
  let block = (
    <div className={`flex flex-col ${customClass ?? ''}`}>
      {label && (
        <Label id={id} text={text} required={required} wordCount={wordCount} />
      )}
      {fieldType === "input" && (
        <Input
          register={register}
          type={type}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          id={id}
          value={value}
        />
      )}
      {fieldType === "textarea" && (
        <TextArea
          register={register}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          id={id}
          rows={rows}
          value={value}
        />
      )}
      {fieldType === "select" && (
        <Select
          register={register}
          text={text}
          required={required}
          disabled={disabled}
          id={id}
          options={options}
          changeHandler={changeHandler}
        />
      )}
      <FormError error={errors[id]} />
      {ariaDescribedby && (
        <div
          id={ariaDescribedby.id}
          className="text-[12px] leading-5 mt-1 text-greyColor6 font-medium md:text-[13px] md:leading-[18px]"
        >
          {ariaDescribedby.text}
        </div>
      )}
    </div>
  );

  return <React.Fragment>{block}</React.Fragment>;
};

FormField.defaultProps = {
  label: true,
  required: false,
  type: "text",
  fieldType: "input",
  placeholder: "",
};

FormField.propTypes = {
  label: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string.isRequired,
  wordCount: PropTypes.object,
  options: PropTypes.object,
  fieldType: PropTypes.string.isRequired,
  id: PropTypes.string,
  rows: PropTypes.number,
  checkboxClass: PropTypes.string,
  labelClass: PropTypes.string,
  text: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.object,
};

export default FormField;
