import React from "react";
import IconAlert from "../Icons/IconAlert";

const FormError = ({error}) => {

    if (!error) return null;

    return (
        <div className="w-full flex items-center gap-x-[5px] mt-1 text-[12px] text-primaryColor4 font-medium md:text-[14px]">
            <IconAlert />
            {error.message}
        </div>
    )
}

export default FormError