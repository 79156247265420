import React from "react";
import {useSidebarProviderContext} from "../../Providers/SidebarProvider";
import {FaBars, FaExternalLinkAlt} from 'react-icons/fa'
import LinkButton from "../Button/LinkButton";

const TopNav = () => {

    const {sidebar, sidebarHandle} = useSidebarProviderContext();

    return (
        <section
            className='w-full flex justify-between items-center z-10 px-6 py-4 bg-white shadow-top-nav md:px-11 md:py-5'>
            <div className='hamburger'>
                <FaBars className='cursor-pointer' onClick={() => sidebarHandle(!sidebar)}/>
            </div>
            <div className='flex flex-1 justify-end items-center gap-2.5'>
                <span className='hidden text-[13px] leading-[20px] font-medium md:block'>Autogenerate outreach content when you visit any LinkedIn profile with our Chrome extension</span>
                <LinkButton text='Chrome Extension' target='_blank'
                            link='https://chrome.google.com/webstore/detail/outreachai/dflaihboigogikfpkgkoniicfoojcfbc'
                            icon={<FaExternalLinkAlt/>}/>
            </div>
        </section>
    );
};

export default TopNav;
