import { useRef, useState } from "react";
import { format } from "date-fns";
import { FaStar, FaRegCopy, FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContentProviderContext } from "../../Providers/ContentProvider";
import { handleUpdateContent } from "../../services/content";
import { tr } from "date-fns/locale";

const CardItems = ({ message }) => {
  //const {handleFav} = useContentProviderContext();
  const { favorites, setFavorites } = useContentProviderContext();
  const [isFav, setIsFav] = useState(false);

  //const favoritesMethod = setFavorites;

  const handleFav = async (id, isFav) => {
    console.log(id);
    const data = favorites;
    //setFavorites({ data: data.data, isLoading: true });
    const res = await handleUpdateContent({
      content_id: id,
      type: "fav",
      is_fav: !isFav,
    });

    // //setIsFav(true);
    // console.log(res);
    // let data1;
    // if (data.data.id === id){
    //     data1 = res.data.data;
    // } else {
    //     data1 = data.data;
    // }
    setFavorites({
      data: [...favorites.data, res.data.data], 
      isLoading: false,
    });

    console.log(favorites);
    
  };

 

  var date = new Date(message.created_at * 1000);
  var formattedDate = format(date, "H:mma, MMMM do, yyyy");
  console.log(formattedDate);
  const textAreaRef = useRef(null);

  function copyToClipboard() {
    navigator.clipboard.writeText(message.response);
    toast("Copied!");
  }

  return (
    <div className="card flex flex-col rounded-lg bg-white p-6 border-b-[0.8px] md:p-10">
      <p
        className="text-[14px] leading-[22px] mb-4 md:text-[16px] leading-[24px]"
        ref={textAreaRef}
      >
        {message.response}
      </p>
      <div className="flex justify-between items-center">
        <div className="flex flex-wrap gap-3 items-center">
          <div className="text-[13px] leading-[20px] text-primaryColor3 text-opacity-70 font-bold">
            Generated - {formattedDate}
          </div>
          <ul className="flex flex-wrap gap-2 items-center">
            <li
              className="cursor-pointer flex justify-center items-center w-8 h-8 bg-greyColor2 rounded text-white hover:text-primaryColor1"
              onClick={copyToClipboard}
            >
              <FaRegCopy />
              <ToastContainer autoClose={1000} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CardItems;
