const AuthMessages = [
    {
        "path": "/login",
        "head": "Welcome back to OutreachAI!",
        "texts": [
            {
                "text": "It's great to see you again and we're glad to have you back with us. Whether you've been away for a while or just taking a short break, we hope that you return to OutreachAI with renewed energy and excitement."
            },
            {
                "text": "As always, we remain committed to providing you with the latest and most effective tools and resources to help you achieve your outreach goals. From AI-powered outreach automation to expert guidance on best practices, we've got you covered."
            },
            {
                "text": "We understand that your time is valuable, so we've made it our mission to create a platform that is both easy to use and highly effective. Our team of experts is always on hand to answer any questions you may have and provide you with personalized support whenever you need it."
            }
        ]
    },
    {
        "path": "/linkedin",
        "head": "Welcome back to OutreachAI!",
        "texts": [
            {
                "text": "It's great to see you again and we're glad to have you back with us. Whether you've been away for a while or just taking a short break, we hope that you return to OutreachAI with renewed energy and excitement."
            },
            {
                "text": "As always, we remain committed to providing you with the latest and most effective tools and resources to help you achieve your outreach goals. From AI-powered outreach automation to expert guidance on best practices, we've got you covered."
            },
            {
                "text": "We understand that your time is valuable, so we've made it our mission to create a platform that is both easy to use and highly effective. Our team of experts is always on hand to answer any questions you may have and provide you with personalized support whenever you need it."
            }
        ]
    },
    {
        'path': "/register",
        'head': "Welcome to Outreach AI!",
        "texts": [
            {
                "text": "We're thrilled to have you join our community of innovative and forward-thinking individuals at OutreachAI. As one of the leading platforms for AI-powered outreach and communication, we are dedicated to helping you unlock the full potential of your outreach efforts."
            },
            {
                "text": "Whether you're a seasoned professional looking to optimize your outreach strategies or a newcomer eager to learn and grow, OutreachAI is designed to provide you with the tools, knowledge, and support you need to succeed. Our platform combines cutting-edge artificial intelligence technology with user-friendly interfaces, making it easy for you to streamline your outreach campaigns and achieve impactful results."
            }
        ]
    },
    {
        'path': "/forgot-password",
        'head': "Welcome to Outreach AI!",
        "texts": [
            {
                "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip."
            }
        ]
    },
    {
        'path': "/reset-password",
        'head': "Welcome to Outreach AI!",
        "texts": [
            {
                "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip."
            }
        ]
    },
    {
        'path': "/onboard",
        'head': "Welcome to Outreach AI!",
        "texts": [
            {
                "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip."
            }
        ]
    }
];

export default AuthMessages;