import axiosInstance from "../AxiosInstance";

export const paymentMethod = async () => await axiosInstance.get('/payments/payment');

export const cancelPlan = async () => await axiosInstance.get('/payments/cancelPlan');

export const mUpdateCard = async (data) => await axiosInstance.post('/payments/updateCard', data);

export const refund = async (data) => await axiosInstance.post('/payments/refund', data);

export const paymentFailed = async (data) => await axiosInstance.post('/payments/paymentFailed', data);

export const subscribe = async (data) => await axiosInstance.post('/payments/subscribe', data);

export const changePlan = async (data) => await axiosInstance.post('/payments/changePlan', data);


// export const getProfile = async token => await axiosInstance.get('/payments/payment',
//     {
//         headers: {
//             'Authorization': 'Bearer ' + token
//         }
//     }
// );