export default function IconGoogle(){
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none">
            <g clip-path="url(#clip0_308_1925)">
                <path d="M6.27041 1.18228C4.47194 1.80619 2.92093 2.99039 1.84522 4.56094C0.7695 6.13149 0.225778 8.00561 0.293912 9.90802C0.362046 11.8104 1.03845 13.6408 2.22376 15.1304C3.40907 16.62 5.04082 17.6902 6.87932 18.1838C8.36984 18.5685 9.93146 18.5853 11.4299 18.2331C12.7874 17.9281 14.0425 17.2759 15.0721 16.3403C16.1438 15.3367 16.9217 14.06 17.3221 12.6474C17.7572 11.1113 17.8347 9.49595 17.5485 7.92525H9.17854V11.3973H14.0259C13.929 11.951 13.7214 12.4796 13.4155 12.9512C13.1096 13.4229 12.7117 13.828 12.2456 14.1423C11.6537 14.534 10.9865 14.7974 10.2867 14.9157C9.58487 15.0462 8.86502 15.0462 8.16323 14.9157C7.45188 14.7688 6.77897 14.4752 6.18745 14.0537C5.23703 13.3809 4.5234 12.4251 4.14838 11.3228C3.76713 10.1997 3.76713 8.98222 4.14838 7.85916C4.41533 7.07196 4.85662 6.35522 5.43932 5.76244C6.10615 5.07162 6.95038 4.57781 7.87938 4.3352C8.80839 4.0926 9.78626 4.11056 10.7057 4.38713C11.424 4.60752 12.0809 4.99277 12.6239 5.51213C13.1704 4.96838 13.716 4.42322 14.2607 3.87666C14.542 3.58275 14.8485 3.30291 15.1256 3.00197C14.2966 2.23067 13.3237 1.63046 12.2624 1.23572C10.3298 0.533989 8.21523 0.51513 6.27041 1.18228Z" fill="white"/>
                <path d="M6.27041 1.18199C8.21506 0.514384 10.3297 0.532746 12.2624 1.23402C13.3239 1.63144 14.2964 2.23454 15.1242 3.00871C14.8429 3.30965 14.5462 3.5909 14.2593 3.8834C13.7137 4.42808 13.1685 4.9709 12.6238 5.51184C12.0809 4.99248 11.424 4.60723 10.7057 4.38683C9.78656 4.1093 8.80871 4.0903 7.87946 4.33191C6.95021 4.57352 6.10547 5.06642 5.4379 5.75652C4.8552 6.34931 4.41391 7.06605 4.14697 7.85324L1.23181 5.59621C2.27526 3.527 4.08193 1.94421 6.27041 1.18199Z" fill="#E33629"/>
                <path d="M0.458429 7.83164C0.615 7.05507 0.875137 6.30304 1.23187 5.5957L4.14702 7.85836C3.76577 8.98142 3.76577 10.1989 4.14702 11.322C3.17577 12.072 2.20405 12.8257 1.23187 13.5832C0.339109 11.8061 0.0668339 9.78141 0.458429 7.83164Z" fill="#F8BD00"/>
                <path d="M9.1786 7.92383H17.5486C17.8348 9.49452 17.7573 11.1099 17.3222 12.646C16.9217 14.0586 16.1439 15.3353 15.0722 16.3388C14.1314 15.6048 13.1864 14.8763 12.2456 14.1423C12.7121 13.8277 13.1102 13.4221 13.4161 12.95C13.722 12.4778 13.9294 11.9487 14.0259 11.3945H9.1786C9.1772 10.2385 9.1786 9.08117 9.1786 7.92383Z" fill="#587DBD"/>
                <path d="M1.23047 13.5835C2.20266 12.8335 3.17437 12.0798 4.14562 11.3223C4.52139 12.425 5.23604 13.3809 6.1875 14.0532C6.78087 14.4728 7.45523 14.764 8.1675 14.9082C8.8693 15.0387 9.58914 15.0387 10.2909 14.9082C10.9907 14.7899 11.658 14.5265 12.2498 14.1348C13.1906 14.8688 14.1356 15.5973 15.0764 16.3313C14.0469 17.2675 12.7918 17.9202 11.4342 18.2255C9.93573 18.5778 8.37411 18.5609 6.88359 18.1763C5.70474 17.8616 4.60361 17.3067 3.64922 16.5465C2.63915 15.7444 1.81411 14.7337 1.23047 13.5835Z" fill="#319F43"/>
            </g>
            <defs>
                <clipPath id="clip0_308_1925">
                <rect width="18" height="18" fill="white" transform="translate(0 0.589844)"/>
                </clipPath>
            </defs>
        </svg>

    )
}