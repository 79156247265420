import React from "react";
import {useNavbarTextProviderContext} from "../../Providers/NavbarTextProvider";
import NavLinks from "./NavLinks";

const Navbar = () => {

    const {nav: {title, text}} = useNavbarTextProviderContext();

    return (
        <React.Fragment>
            <h5 className='text-primaryColor1 text-[13px] leading-[20px] font-medium mb-0 uppercase'>{title}</h5>
            <h6 className='text-white opacity-50 text-[12px] leading-[16.5px] font-medium mb-4'>{text}</h6>
            <NavLinks/>
        </React.Fragment>
    );
};

export default Navbar;
