import React from "react";

const Loader = () => {
    return (
        <div className='flex justify-center min-h-screen items-center'>
            <div
                className="loader border-8 border-solid border-greyColor2 border-t-8 border-t-solid border-t-primaryColor1 rounded-full w-[80px] h-[80px] animate-spin"></div>
        </div>
    );
};

export default Loader;
