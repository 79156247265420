import React from "react";
import PropTypes from "prop-types";

const TextArea = ({placeholder, id, rows, value, register, disabled}) => {
    return (
        <textarea name={id} id={id} disabled={disabled}
                  className={`w-full px-3 py-1.5 text-[13px] leading-[20px] border text-greyColor5 border-solid border-greyColor4 rounded-md md:text-[16px] md:leading-6 ${disabled && `disabled:bg-greyColor2 disabled:cursor-not-allowed`}`} {...register(id)}
                  placeholder={placeholder} rows={rows}>{value}</textarea>
    );
};

TextArea.defaultProps = {
    rows: 7
}

TextArea.propTypes = {
    required: PropTypes.bool,
    id: PropTypes.string,
    rows: PropTypes.number,
    text: PropTypes.string,
    placeholder: PropTypes.string
}

export default TextArea;