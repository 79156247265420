import React from "react";
import PropTypes from "prop-types";

const Option = ({title,value}) => {
    return (
        <option value={value}>{title}</option>
        // <option value={title}>{title}</option>
    );
};

Option.propTypes = {
    title: PropTypes.string.isRequired,
}

export default Option;