import React from "react";
import {createContext, useContext, useState} from 'react';
import {isMobile} from "react-device-detect";

export const SidebarContext = createContext(null)

export const SidebarProvider = ({children}) => {

    const [sidebar, setSidebar] = useState(!isMobile)

    const sidebarHandle = (opened) => {
        setSidebar(opened)
    }

    return <SidebarContext.Provider value={{sidebar, sidebarHandle}}>
        {children}
    </SidebarContext.Provider>
};

export const useSidebarProviderContext = () => useContext(SidebarContext);
