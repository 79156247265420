import React from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {useNavbarTextProviderContext} from "../../Providers/NavbarTextProvider";
import {useSidebarProviderContext} from "../../Providers/SidebarProvider";

const NavItem = ({item}) => {

    const {title, text, link, icon} = item;
    
    const {navTextHandler} = useNavbarTextProviderContext();
    const {sidebarHandle} = useSidebarProviderContext();

    const navClickHandler = () => {
        navTextHandler(title, text)
        if (isMobile) sidebarHandle()
    }

    return (
        <li><NavLink activeclassname='active' onClick={() => navClickHandler()}
                     className='px-4 py-3 text-[14px] text-white text-opacity-80 flex items-center flex-row gap-2.5 rounded-md font-medium transition-all duration-500 hover:text-opacity-100 hover:bg-black bg-opacity-50'
                     to={link}>{icon} {title}</NavLink>
        </li>
    );

};

NavItem.propTypes = {
    item: PropTypes.object.isRequired
}

export default NavItem;
