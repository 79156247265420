import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import './index.css';
import 'react-loading-skeleton/dist/skeleton.css'
import App from './App';
import {AuthProvider} from './Providers/AuthProvider';
import {ContentProvider} from './Providers/ContentProvider';
import {SidebarProvider} from './Providers/SidebarProvider';
import {NavbarTextProvider} from './Providers/NavbarTextProvider';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-PCS3J44J'
}

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <AuthProvider>
            <ContentProvider>
                <SidebarProvider>
                    <NavbarTextProvider>
                        <App/>
                    </NavbarTextProvider>
                </SidebarProvider>
            </ContentProvider>
        </AuthProvider>
    </BrowserRouter>
);
