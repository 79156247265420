const WhatIsLengthOfTheMessage = {
    defaultValue: 'Short Message (~300 characters)',
    data: [
        {
            title: 'Short Message (~300 characters)',
            value: 300,
        },
        {
            title: 'Long Message (~700 characters)',
            value: 650,
        }
    ]
}

export default WhatIsLengthOfTheMessage;