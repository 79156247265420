import React from "react";
import CardItem from "./CardItem";

const Cards = ({ messages, page }) => {
  return (
    <div className="flex flex-col bg-white rounded-lg overflow-hidden">
      {messages.map((message, index) => (
        <CardItem key={index} message={message} page={page} />
      ))}
    </div>
  );
};

export default Cards;
