import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Components/Breadcrumbs";
import Cards from "../../Components/Cards/Cards";
import { axiosInterceptor } from "../../AxiosInstance";
import { handleGetContent } from "../../services/content";
import { useAuthProviderContext } from "../../Providers/AuthProvider";
import { useContentProviderContext } from "../../Providers/ContentProvider";
import { usageInfo } from "../../services/user";
import { fnAddUserToDrip } from "../../utils/global";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const History = () => {
  const { auth } = useAuthProviderContext();
  const { history, setHistory } = useContentProviderContext();

  const navigate = useNavigate();
  const [showUpgrade, setShowUpgrade] = useState(process.env.NODE_ENV==='development' ? false : true);

  useEffect(() => {
    console.log(auth.user);
    if (auth.user) {
      try {
        (async () => {
          const res = await usageInfo();
          console.log(res);
          if (res.status == 200) {
            if (res.data.expired && showUpgrade) {
              fnAddUserToDrip();
              confirmAlert({
                title: "",
                message:
                  "You have exhausted your credits. Please upgrade your plan now",
                buttons: [
                  {
                    label: "Pay Now",
                    onClick: async () => {
                      navigate("/payment");
                    },
                  },
                  {
                    label: "Cancel",
                    onClick: () => {},
                  },
                ],
              });
            } else if (res.data.expiringSoon && showUpgrade) {
              confirmAlert({
                title: "",
                message:
                  "Your credits are going to be finished. Please upgrade your plan now",
                buttons: [
                  {
                    label: "Pay Now",
                    onClick: async () => {
                      navigate("/payment");
                    },
                  },
                  {
                    label: "Cancel",
                    onClick: () => {},
                  },
                ],
              });
            }
            setShowUpgrade(false);
          }
        })();
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  useEffect(() => {
    if (auth.user) {
      (async () => {
        try {
          const res = await handleGetContent();
          setHistory({
            isLoading: false,
            data: res.data.data,
          });
        } catch (err) {
          console.error(err);
        }
      })();
    }
  }, []);

  if (history.isLoading) return "Loading...";

  return (
    <React.Fragment>
      <div className="bg-white">
        <Breadcrumbs pageTitle="History" title="Content Generation History" />
      </div>
      <div className="bg-greyColor3 py-6 px-6 md:py-11 md:px-14">
        <div className="bg-white rounded-lg max-w-[800px]">
          {history.data.length ? (
            <Cards messages={history.data} page="history" />
          ) : (
            "No favorites found"
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default History;
