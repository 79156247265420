import React from "react";
import { Link } from "react-router-dom";

const IconButton = ({ icon, notification, dropDownOptions }) => {
  let block = (
    <button
      type="button"
      className="flex justify-center relative items-center w-[30px] h-[24px] text-greyColor1 md:text-[20px] group"
    >
      {icon}
      {notification && (
        <span
          className={`bg-primaryColor1 text-white flex justify-center items-center text-[11px] leading-[11px] font-medium w-[20px] h-[20px] absolute top-[-14px] right-[-3px] rounded-full`}
        >
          {notification}
        </span>
      )}
    </button>
  );

  if (dropDownOptions) {
    block = (
      <div className="group relative">
        <button
          type="button"
          className="flex justify-center relative items-center w-[30px] h-[24px] text-greyColor1 md:text-[20px] group"
        >
          {icon}
          {notification && (
            <span
              className={`bg-primaryColor1 text-white flex justify-center items-center text-[11px] leading-[11px] font-medium w-[20px] h-[20px] absolute top-[-14px] left-[5px] rounded-full`}
            >
              {notification}
            </span>
          )}
          <svg
            className="fill-current h-4 w-4 group-hover:rotate-180 transition-transform"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </button>
        <ul className="rounded overflow-hidden absolute hidden text-gray-700 py-2 bg-greyColor3 group-hover:block w-[160px] right-0">
          {dropDownOptions.map((item, index) => (
            <li key={index} className="text-[14px] hover:bg-greyColor4">
              <Link
                to={item.link}
                onClick={item.handleClick}
                className="py-1 px-4 block"
              >
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return <React.Fragment>{ block }</React.Fragment>;
};

export default IconButton;
