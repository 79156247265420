import {
    FaTachometerAlt,
    FaRegStar,
    FaHistory,
    FaRegCreditCard,
    FaRegUser,
    FaQuestionCircle,
    FaQuestion
} from 'react-icons/fa'


const Navigation = [
    {
        'link': '/',
        'title': 'LinkedIn Outreach',
        'text': 'Autogenerate outreach content when you visit any LinkedIn profile.',
        'breadcrumbText': 'LinkedIn AI Outreach Generator',
        'icon': <FaTachometerAlt/>
    },
    {
        'link': '/favorites',
        'title': 'Favorites',
        'text': 'All of your favorite generated Outreach AI messages for quick use.',
        'breadcrumbText': 'Your Favorites',
        'icon': <FaRegStar/>
    },
    {
        'link': '/history',
        'title': 'History',
        'text': 'In case you want to use your past used Outreach AI generated messages.',
        'breadcrumbText': 'Content Generation History',
        'icon': <FaHistory/>
    },
    {
        'link': '/payment',
        'title': 'Payment',
        'text': 'Manage your payments to upgrade yourself to more features provided by us.',
        'breadcrumbText': 'Payment Profile',
        'icon': <FaRegCreditCard/>
    },
    {
        'link': '/profile',
        'title': 'Profile',
        'text': 'Manage your profile, be more professional and accurate before you reach someone.',
        'breadcrumbText': 'Your Profile',
        'icon': <FaRegUser/>
    },
    {
        'link': '/help',
        'title': 'Help',
        'text': 'Any questions about us? Reach our help section to frequently asked questions.',
        'breadcrumbText': 'Support',
        'icon': <FaQuestionCircle/>
    },
    {
        'link': '/faqs',
        'title': 'Faqs',
        'text': 'Any questions about us? Reach our help section to frequently asked questions.',
        'breadcrumbText': 'Frequently Asked Questions',
        'icon': <FaQuestion/>
    }
]

export default Navigation;