const GenerateContentUsingReceivers = {
    defaultValue: 'LinkedIn Bio',
    data: [
        {
            title: 'LinkedIn Bio',
            value: 'li_bio_outreach'
        },
        {
            title: 'LinkedIn Recommendation',
            value: 'li_recommendation_outreach'
        },
        {
            title: 'LinkedIn Post',
            value: 'li_post_outreach'
        }
    ]
}
export default GenerateContentUsingReceivers;