import React from "react";
import NavItem from "./NavItem";
import Navigation from "../../Data/Navigation";

const NavLinks = () => {
    return (
        <ul className='text-greyColor1 m-0 p-0 list-none flex flex-col space-y-1'>
            {Navigation.map((item, index) => <NavItem key={index} item={item}/>)}
        </ul>
    );
};

export default NavLinks;
