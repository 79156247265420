import React from "react";
import {createContext, useContext, useState} from 'react';

export const NavbarTextContext = createContext(null);

export const NavbarTextProvider = ({children}) => {

    const [nav, setNav] = useState({
        title: 'LinkedIn Outreach',
        text: 'Autogenerate outreach content when you visit any LinkedIn profile.'
    })

    const navTextHandler = (title, text) => {
        setNav({title, text})
    }


    return <NavbarTextContext.Provider value={{nav, navTextHandler}}>
        {children}
    </NavbarTextContext.Provider>
};

export const useNavbarTextProviderContext = () => useContext(NavbarTextContext);
